<template>
  <div class="el-input">
    <money class="el-input__inner" ref="moneyInput" @change="onChange" v-model="price" v-bind="money" />
  </div>
</template>

<script>
import { Money } from 'v-money';

export default {
  components: { Money },
  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    masked: Boolean,
    precision: {
      value: 2,
      type: [Number, String],
      default: 2
    }
  },
  mounted() {
    this.$refs.moneyInput.$el.addEventListener('keydown', function($event) {
      $event.key === '-' ? $event.preventDefault() : null
    })
  },
  data() {
    return {
      price: this.value,
      money: {
        decimal: '.',
        prefix: '$ ',
        suffix: ' ',
        precision: this.precision,
        masked: this.masked || false
      }
    };
  },
  methods: {
    onChange(v) {
      if(v && v.replace) {
        this.price = v.replace('-', '')
      }
    }
  },
  watch: {
    value: function (newVal, oldVal) {
      if(newVal !== oldVal) {
        this.price = newVal;
      }
    },
    price: function (newVal, oldVal) {
      if(newVal !== oldVal) {
        let amount = newVal;
        if (this.masked && newVal.replace) {
          amount = newVal.replace('$ ', '').replaceAll(',', '');
        }
        this.$emit('input', (''+amount));
      }
    }
  }
};
</script>
