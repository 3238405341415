<template>
  <div class="widgetContainer bg-white spend">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          {{
            getReplaceCardData
              ? $t('card.text.replaceCard')
              : $t(
                getSelectedCard
                  ? getSelectedCard.cardType === 'physical'
                    ? 'physicalCard_edit_NavTitle'
                    : 'virtualCard_edit_NavTitle'
                  : getCreateCardData.cardType === 'physical'
                    ? 'physicalCard_Create_NavTitle'
                    : 'virtualCard_Create_NavTitle'
              )
          }}
        </p>
        <span class="el-icon-close cancel" @click="drawerClose" />
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form class="wise-form" ref="cardDetail" :model="formData" :rules="formRules">
        <el-form-item class="is-no-asterisk" prop="label" :label="$t('card_label')">
          <el-input name="label" v-model="formData.label" />
        </el-form-item>

        <el-form-item v-if="!getSelectedCard && getCreateCardData.cardType === 'physical'" class="is-no-asterisk"
          prop="embossingPerson" :label="$t('embossing_person_name')">
          <el-input name="label" v-model="formData.embossingPerson" :maxlength="21" />
        </el-form-item>

        <el-form-item v-if="isBusinessAccountType && !getSelectedCard && getCreateCardData.cardType === 'physical'"
          class="is-no-asterisk" prop="embossingBusiness" :label="$t('embossing_business_name')">
          <el-input name="label" v-model="formData.embossingBusiness" :maxlength="23" />
        </el-form-item>

        <el-form-item class="is-no-asterisk" prop="limitInterval" :label="$t('card_spendLimit')">
          <Amount v-model="formData.limitAmount" :masked="true" />
        </el-form-item>
        <div class="spend__limitinterval">
          <ul>
            <li v-for="limit in spendLimit" @click="selectInterval(limit.key)" :key="limit.key"
              :class="{ selected: formData.limitInterval === limit.key }">
              <el-radio class="brand" v-model="formData.limitInterval" :label="limit.key">
                {{ limit.value }}
              </el-radio>
            </li>
          </ul>
        </div>
      </el-form>
    </div>
    <div class="el-drawer__footer">
      <el-button data-testid="submit" type="primary" @click="next" class="el-button__brand brand width-100"
        :disabled="!isFormValid">
        {{ $t('kyb_NavRightButton_Title') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { cardLimit } from '@m/card/enums';
import CardMixin from '../mixins/card';
import Amount from '@/components/Amount';
export default {
  name: 'CardLabelForm',
  mixins: [CardMixin],
  components: {
    Amount
  },
  data() {
    return {
      formData: {
        label: '',
        limitAmount: '',
        limitInterval: '',
        currency: 'USD'
      },
      formRules: {}
    };
  },
  created() {
    /** @type {import('@m/card/store/state').cardState['selectedCard']} */
    const card = this.getSelectedCard;
    if (this.getReplaceCardData && this.getReplaceCardData.limitInterval) {
      this.formData.label = this.getReplaceCardData.label;
      this.formData.limitAmount = this.getReplaceCardData.limitAmount;
      this.formData.limitInterval = this.getReplaceCardData.limitInterval;
    } else if (card && card.limitInterval) {
      this.formData.limitAmount = card.limitAmount;
      this.formData.limitInterval = card.limitInterval;
      this.formData.label = card.label;
    }
  },
  computed: {
    ...mapGetters('card', [
      'getCreateCardData',
      'getSelectedCard',
      'getReplaceCardData'
    ]),
    ...mapGetters('account', ['isBusinessAccountType']),
    isFormValid() {
      return (
        this.formData.limitAmount !== '' &&
        this.formData.limitInterval !== '' &&
        this.formData.label !== ''
      );
    },
    spendLimit() {
      const data = [];
      for (let key in cardLimit) {
        if (key == 'cardBoxTitle') {
          continue;
        }
        data.push({
          key,
          value: cardLimit[key]
        });
      }
      return data;
    }
  },
  methods: {
    ...mapMutations('card', ['updateCreateCardData', 'updateReplaceCardDate']),
    ...mapActions('card', ['updateCard']),
    cancel() {
      if (this.getSelectedCard) {
        this.$router.replace('/card/cardManagement');
      } else if (this.getReplaceCardData) {
        this.$router.push('/card/replaceCardDetails');
      } else {
        this.drawerBack();
      }
    },
    next() {
      /** @type {import('@m/card/store/state').cardState['selectedCard']} */
      const selectedCard = this.getSelectedCard;
      if (selectedCard && this.getReplaceCardData) {
        //replace flow

        this.updateReplaceCardDate({
          ...this.getReplaceCardData,
          label: this.formData.label,
          limitAmount: this.formData.limitAmount,
          limitInterval: this.formData.limitInterval
        });
        setTimeout(() => {
          this.$router.push('/card/replaceCardDetails');
        }, 0);

        return;
      }

      if (selectedCard) {
        const loader = this.showLoader();
        this.updateCard({
          cardId: selectedCard.id,
          payload: {
            label: this.formData.label,
            limitAmount: this.formData.limitAmount,
            limitInterval: this.formData.limitInterval
          }
        })
          .then(() => {
            this.$emit('refresh');
            this.drawerPush('CardDetails');
          })
          .catch((e) => {
            console.log(e);
            this.apiErrorMessage(e);
          })
          .finally(() => {
            loader.close();
          });
        return;
      }

      /** @type {import('@/modules/card/store/state').cardState['createCardData]} */
      const card = {
        ...this.getCreateCardData,
        ...this.formData
      };
      this.updateCreateCardData(card);
      this.drawerPush('CardBillingAddressForm');
    },
    selectInterval(v) {
      this.formData.limitInterval = v;
    }
  }
};
</script>
<style lang="scss">
.spend {
  .wise-form .el-form-item__label {
    text-transform: unset;
  }

  &__limitinterval {
    ul {
      background: #fff;
      border-radius: 10px;
      padding: 10px 16px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        cursor: pointer;
        text-transform: capitalize;

        &:first-of-type {
          padding-top: 0;
        }

        &:last-of-type {
          padding-bottom: 0;
        }

        &:hover {
          background: none;

          .icon-tick {
            opacity: 0.4;
          }
        }

        &.selected {
          .icon-tick {
            opacity: 1;
          }
        }

        p {
          color: #3c3c43;
        }

        .icon-tick {
          font-size: 14px;
          color: var(--branding);
          transition: 0.2s;
          opacity: 0;
        }
      }
    }
  }
}
</style>
